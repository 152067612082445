<template>
    <div class="flex md:flex-row md:justify-between md:items-center flex-col">
        <AvatarInfo class="mb-4 md:mb-0" :title="avatarOptions.title" :subtitle="avatarOptions.subtitle" :subtitle-options="avatarOptions.subtitleOption" :avatar-actions="avatarOptions.avatarActions">
            <template v-slot:avatar>
                <Avatar :text="avatarOptions.title" :image-url="avatarOptions.imageUrl" :size="64"></Avatar>
            </template>
        </AvatarInfo>
        <h4 class="primary m-0">🔒 Secure Payment Page</h4>
    </div>
</template>

<script>
import {Avatar, AvatarInfo} from '@e9ine/vue_components';

export default {
    name: 'Header',
    props: {
        company: {
            type: Object
        }
    },
    components: {
        Avatar,
        AvatarInfo
    },
    data() {
        return {
            avatarOptions: {
                title: null,
                imageUrl: null,
                subtitle: null
            }
        };
    },
    created() {
        this.avatarOptions.title = this.company.Name;
        this.avatarOptions.imageUrl = `${this.company.LogoUrl}`;
        this.avatarOptions.subtitle = `${this.company.Address1}, ${this.company.TownCity}, ${this.company.Postcode}`;
    }
};
</script>

<style lang="scss"></style>
