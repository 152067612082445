<template>
    <div ref="journeyDetailsCard">
        <Card
            :title="booking.Booking.LeadPassenger.Firstname + '\'s Journey'"
            sub-title="Please find the journey details below."
            :options="cardOptions"
            :card-media-image="imageUrl"
            :card-media-image-height="mapHeight">
            <template v-slot:cardCustomContent>
                <div class="grid grid-cols-12 py-2 px-4">
                    <div class="col-span-8">
                        <div class="mb-2">
                            <strong class="text-sm block primary">Journey Date</strong>
                            {{ bookingDate }}
                        </div>
                        <div v-for="(stop, key) in booking.Booking.BookingStops" class="mb-2" :conversation="stop" :key="key">
                            <strong class="text-sm block primary" v-if="key == 0">From</strong>
                            <strong class="text-sm block primary" v-if="key == booking.Booking.BookingStops.length - 1">To</strong>
                            <strong class="text-sm block primary" v-if="key !== 0 && key !== booking.Booking.BookingStops.length - 1">Via</strong>
                            {{ stop.StopSummary }}
                        </div>

                        <div class="my-2">
                            <strong class="text-sm block primary">Total Cost</strong>
                            <span class="t-l font-bold">
                                {{ booking.BookingFinance.Total | currency }}
                            </span>
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="mb-2">
                            <strong class="text-sm block primary">Booking Id</strong>
                            {{ booking.Booking.LocalId }}
                        </div>
                        <div class="mb-2">
                            <strong class="text-sm block primary">Vehicle Type</strong>
                            {{ booking.Booking.VehicleType.Name }}
                        </div>
                        <div class="mb-2">
                            <strong class="text-sm block primary">Distance</strong>
                            {{ booking.BookingFinance.EstimatedDistance }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:cardExpandedContent>
                <FareBreakdown v-if="!isMetered" :booking="booking.BookingFinance" />
            </template>
        </Card>
    </div>
</template>

<script>
import {Card} from '@e9ine/vue_components';
import FareBreakdown from '@/components/FareBreakdown';
import moment from 'moment-timezone';

export default {
    name: 'JourneyDetails',
    props: {
        booking: {
            type: Object
        },
        isMetered: {
            type: Boolean
        }
    },
    components: {
        Card,
        FareBreakdown
    },
    data() {
        return {
            imageUrl: this.booking.Booking.ImageUrl,
            cardOptions: {
                expandedContentTitle: 'Fare Breakdown',
                cardInfoLocation: 'middle'
            },
            bookingDate: moment(this.booking.Booking.BookedDateTime)
                .tz('Europe/London')
                .format('DD/MM/YYYY HH:mm'),
            passengerName: `${this.booking.Booking.LeadPassenger.Firstname}  ${this.booking.Booking.LeadPassenger.Surname}`,
            mapHeight: 200
        };
    },
    methods: {
        adjustMapToWidth() {
            setTimeout(() => {
                this.mapHeight = this.$refs['journeyDetailsCard'].clientWidth;
            }, 0);
        }
    },
    created() {
        this.adjustMapToWidth();
        window.addEventListener('resize', this.adjustMapToWidth);
    }
};
</script>
<style lang="scss" scoped></style>
