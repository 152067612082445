import '@/scss/style.scss';
import '@e9ine/vue_components/src/filters';
import App from './App.vue';
import jQuery from 'jquery';
import store from '../../store';
import Vue from 'vue';

const $ = jQuery;
window.$ = $;
Vue.config.productionTip = false;

new Vue({
	store,
	render: h => h(App)
}).$mount('#app');
