<template>
    <div class="p-4">
        <h6>Fare Breakdown</h6>
        <p class="body-s">
            Please find below a breakdown for how the fare has been calculated.
        </p>
        <table class="w-full">
            <thead>
                <tr>
                    <th class="text-left">
                        Description
                    </th>
                    <th class="text-right">
                        Cost
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-left">Journey Cost</td>
                    <td class="text-right">{{ booking.ActualCost | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Waiting Time ({{ booking.WaitingTime }} mins)</td>
                    <td class="text-right">{{ booking.WaitingCost | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Adjustments</td>
                    <td class="text-right">{{ booking.AdjustmentsTotal | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Extras</td>
                    <td class="text-right">{{ booking.ExtrasTotal | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Sub Total</td>
                    <td class="text-right">{{ booking.TotalAmount | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Tax</td>
                    <td class="text-right">{{ booking.TotalTax | currency }}</td>
                </tr>
                <tr>
                    <td class="text-left">Total Amount</td>
                    <td class="text-right">{{ booking.Total | currency }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'FareBreakdown',
    props: {
        booking: {
            type: Object
        }
    }
};
</script>
