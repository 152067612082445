<template>
    <div class="flex md:justify-between md:items-center flex-col mt-4">
        <div class="text-sm text-center">
            <strong>
                {{ company.Name }}
            </strong>
            <br/>
            <strong>P: </strong><span>{{ company.Phone }}</span> | <strong>E: </strong><span><a
            :href="'mailto:' + company.Email">{{ company.Email }}</a></span>
            <br/>
            <strong class="primary">Reg: </strong>{{ company.RegistrationNumber }} | <strong
            class="primary">VAT: </strong>{{ company.VATNumber }}
            <br/>
            <strong><a :href="company.Website" target="_blank">{{ company.Website }}</a></strong>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        company: {
            type: Object
        }
    }
};
</script>
<style lang="scss" scoped>
strong {
    color: $brand-primary;
}
</style>
