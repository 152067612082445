<template>
    <div id="app" class="container mx-auto p-4 md:my-8 md:p-8 md:rounded-md md:border">
        <div v-if="booking">
            <Header :company="booking.Company" id="header" class="mb-4 md:mb-8" />
            <div id="payment-details" class="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                <div>
                    <JourneyDetails :is-metered="isMetered" :booking="booking" class="mb-4" />
                </div>

                <div v-if="paymentStatus === 'Paid'">
                    <Card
                        class="resizeCardImg"
                        title="Payment Received"
                        sub-title="Your payment has been made. Please find below the fare breakdown"
                        :options="cardOptions"
                        :card-media-image="require('@/assets/confirmed.png')">
                        <template v-slot:cardCustomContent>
                            <FareBreakdown :booking="booking.BookingFinance" />
                        </template>
                    </Card>
                </div>

                <div id="payment-frame-container" v-show="paymentStatus !== 'Paid'" class="h-screen">
                    <iframe :src="getPaymentIframeUrl()" class="w-full h-full" allow="payment" id="payment-frame" @load="setupPaymentFrame"></iframe>
                </div>
            </div>
            <Footer :company="booking.Company" id="footer" />
        </div>
        <div v-else-if="error">
            <Card
                class="resizeCardImg text-center h-screen border-none"
                title="⚠️ 404 Booking Not Found"
                sub-title="There was no booking found for this booking code"
                :options="bookingNotFound"
                :card-media-image="require('@/assets/404.png')"
                :card-media-image-height="400">
            </Card>
        </div>
        <div v-else>
            <div class="p-4">
                <h4 class="primary mb-8">Fetching booking info...</h4>
                <Shimmer>
                    <ShimmerHeading :img="true" />
                    <ShimmerText :lines="4" />
                </Shimmer>
            </div>
        </div>
    </div>
</template>

<script>
import {Card, Shimmer, ShimmerHeading, ShimmerText} from '@e9ine/vue_components';
import axios from 'axios';
import FareBreakdown from '@/components/FareBreakdown';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import JourneyDetails from '@/components/JourneyDetails';

let index = window.location.href.lastIndexOf('=');
let bookingCode = window.location.href.substring(index + 1);
let bookingCodeParams = null;
export default {
    name: 'App',
    components: {
        JourneyDetails,
        Header,
        Footer,
        Shimmer,
        ShimmerHeading,
        ShimmerText,
        Card,
        FareBreakdown
    },
    data() {
        return {
            booking: null,
            error: null,
            cardOptions: {
                cardInfoLocation: 'top'
            },
            bookingNotFound: {
                cardInfoLocation: 'middle'
            },
            paymentStatus: 'Due'
        };
    },
    methods: {
        async getBookingInfo() {
            bookingCodeParams = {
                code: bookingCode
            };
            try {
                let result = await axios({
                    method: 'get',
                    url: `${process.env.VUE_APP_SERVICE_URL}payments/booking-info`,
                    params: bookingCodeParams
                });
                this.booking = result.data;
                this.booking.BookingFinance.TotalAmount =
                    this.booking.BookingFinance.ActualCost + this.booking.BookingFinance.WaitingCost + this.booking.BookingFinance.ExtrasTotal + this.booking.BookingFinance.AdjustmentsTotal;
                this.booking.BookingFinance.TotalTax =
                    this.booking.BookingFinance.ActualTax + this.booking.BookingFinance.WaitingTax + this.booking.BookingFinance.ExtrasTax + this.booking.BookingFinance.AdjustmentsTax;
                this.booking.BookingFinance.Total =
                    this.booking.BookingFinance.ActualCost +
                    this.booking.BookingFinance.WaitingCost +
                    this.booking.BookingFinance.ExtrasTotal +
                    this.booking.BookingFinance.AdjustmentsTotal +
                    this.booking.BookingFinance.ActualTax +
                    this.booking.BookingFinance.WaitingTax +
                    this.booking.BookingFinance.ExtrasTax +
                    this.booking.BookingFinance.AdjustmentsTax;
                if (
                    (this.booking.Booking.CardPaymentStatus == 'Success' && this.booking.Booking.PaymentMethod == 'Card') ||
                    (this.booking.Booking.PaymentMethod == 'OnAccount' && this.booking.Booking.InvoiceId != null) ||
                    (this.booking.Booking.PaymentMethod == 'Cash' && this.booking.Booking.DriverPaymentId != null)
                ) {
                    this.paymentStatus = 'Paid';
                }
            } catch (exc) {
                this.error = exc;
            }
        },
        paymentStatusChange(value) {
            if (value == true) this.paymentStatus = 'Paid';
        },
        getPaymentIframeUrl() {
            return `${process.env.VUE_APP_URL}pay?tenantId=${this.booking.Booking.TenantId}&bookingId=${this.booking.Booking.Id}&passengerId=${this.booking.Booking.LeadPassengerId}&bookingStatus=${this.booking.Booking.BookingStatus}&cardPaymentStatus=${this.booking.Booking.CardPaymentStatus}`;
        },
        setupPaymentFrame() {
            let paymentFrame = document.getElementById('payment-frame');
            paymentFrame.addEventListener('load', () => {
                try {
                    const url = paymentFrame.contentWindow.location.href;
                    if (url.indexOf('success.html') > -1) {
                        location.reload();
                    }
                } catch (e) {
                    console.log(e);
                }
            });
        }
    },
    computed: {
        isMetered() {
            return this.booking.Booking.PricingMode === 'ActualRoute' && this.booking.Booking.BookingStatus === 'InProgress';
        },
        isPreAuthTaken() {
            return this.booking.Booking.CardPaymentStatus && this.booking.Booking.CardPaymentStatus === 'PreauthTaken';
        }
    },
    created() {
        this.getBookingInfo();
    }
};
</script>

<style lang="scss">
.resizeCardImg {
    .card-container {
        .card-media-image {
            background-position: center center;
            background-size: contain;
        }
    }
}
</style>
